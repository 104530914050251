// src/router.js
import { createRouter, createWebHistory } from 'vue-router'

import HomePage from './views/HomePage.vue'
import About from './views/OmOs.vue'
import Services from './views/VoresTjenester.vue'
import Contact from './views/KontaktOs.vue'

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/omos', name: 'OmOs', component: About },
  { path: '/ydelser', name: 'VoresTjenester', component: Services },
  { path: '/kontakt', name: 'KontaktOs', component: Contact }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router