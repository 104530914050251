<template>
 <section id="contact">
    <img class="maps" src="../assets/images/maps.png" alt="">
    <div class="white-container">
      <div class="contact-pc">
        <h2 class="contact-title">Kontakt os</h2>
        <div class="contact-card">
          <h3 class="contact-content-title">Her bor vi</h3>
          <p class="contact-text">Fyrrevej 11, 8680 Ry</p>
        </div>
        <div class="contact-card">
          <h4 class="contact-content-title">Ring eller skriv</h4>
          <p class="contact-text">Telefon: [Indsæt telefonnummer]</p>
          <p class="contact-text">Email: [Indsæt emailadresse]</p>
        </div>
        <p class="contact-content-title bye">Vi ser frem til at høre fra dig</p>
      </div>
      <div>
        <iframe class="googlemaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2226.3196213312485!2d9.767831177180403!3d56.082385766557096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c715ed912a663%3A0xeb0e297afe08863b!2sFyrrevej%2011%2C%208680%20Ry%2C%20Danmark!5e0!3m2!1sda!2sde!4v1723021647541!5m2!1sda!2sde" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
</section>
</template>

<script>
export default {
  name: 'KontaktOs'
}
</script>

<style>

.contact-title {
  font-size: 4rem;
  padding-bottom: 20px;
}

.white-container {
  position: relative;
  top: 30px;
  min-height: 70vh;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 50px;
}

.maps {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100vw;
  max-height: 500px;
}

.contact-content-title {
  font-size: 24px;
  font-weight: bold;
}

.bye {
  margin: 30px 0;
}

.contact-text {
  font-size: 20px;
}

.contact-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}

.googlemaps {
  width: 100%;
  height: 400px;
}

@media (min-width: 1200px) {
  .white-container {
    min-height: 50vh;
    padding: 40px;
    display: flex;
   justify-content: space-between;
    flex-direction: row;
  }

  .contact-pc {
      display: flex;
      flex-direction: column;
  }

  .googlemaps {
    width: 400px;
  }
}

</style>