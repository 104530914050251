<template>
 <section id="services">
    <h2 class="service-title">Vores Ydelser</h2>
    <ul class="service-container">
        <li class="service-card">
            <h3 class="card-title">Rådgivning om energioptimering</h3>
            <p class="card-content">Vi tilbyder ekspert rådgivning for at hjælpe dig med at finde de bedste løsninger til at reducere energiforbruget.</p>
        </li>
        <li class="service-card">
            <h3 class="card-title">Analyse af VVS-systemer</h3>
            <p class="card-content">Grundig analyse af dine nuværende VVS-systemer for at identificere muligheder for optimering.</p>
        </li>
        <li class="service-card">
            <h3 class="card-title">Design af energieffektive løsninger</h3>
            <p class="card-content">Udarbejdelse af skræddersyede design, der maksimerer effektiviteten af dine VVS-systemer.</p>
        </li>
        <li class="service-card">
            <h3 class="card-title">Implementering af optimeringsprojekter</h3>
            <p class="card-content">Vi håndterer hele processen fra planlægning til implementering af dine optimeringsprojekter.</p>
        </li>
    </ul>
</section>
</template>

<script>
export default {
  name: 'VoresTjenester'
}
</script>

<style>
#services {
    min-height: 70vh;
}

.service-title {
  font-size: 4rem;
  padding-bottom: 20px;
}

.service-container {
    height: 100%;
    margin: 20px 0;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.service-card {
    max-width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    border-radius: 5px;
    background-color: rgb(240, 240, 240);
    display: flex;
    padding: 15px;
    flex-direction: column;
    gap: 8px;
}

.card-title {
    font-size: 20px;
    font-weight: bold;
}

.card-content {
    line-height: 1.2;
}

@media (min-width: 768px) {
    .service-card {
    max-width: 400px;
    padding: 20px;

    gap: 8px;
}
}

</style>