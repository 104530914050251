<template>
  <section id="hero">
    <div class="hero"></div>
    <div class="text-box">
      <h1 class="title">Midtjysk Service ApS</h1>
      <p class="front-text">Specialister i energioptimering af VVS-installationer</p>
      <a class="cta-button" href="tel:+4521375039">Kontakt os</a>
    </div>
  </section>
</template>

<script>

</script>

<style>
.hero {
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 100vw;
  min-height: 70vh;
  opacity: 0.5;
  background-image: linear-gradient(0deg, rgba(27, 26, 26, 0.9) 100%, rgba(0, 0, 0, 0) 100%);
}

#hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
  background-image: url("../assets/images/Frontpage.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

.title {
  font-size: 5rem;
  color: #fff;
}

.front-text {
  font-size: 1.7rem;
  line-height: 1.2;
}

.text-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: #fff;
  position: relative;
  z-index: 2;
}

.cta-button{
  text-align: center;
    opacity: 1;
    background-color: #074c77;
    color: #fff;
    border-radius: 5px;
    border: none;
    width: 160px;
    padding: 15px 10px;
}

</style>
