<!-- src/components/Navbar.vue -->
<template>
  <nav class="navbar">
    <router-link router-link to="/" class="logo"><img class="logo" alt="" src="../assets/images/logo.jpg"></router-link>
    <ul class="nav-links" :class="{ 'nav-active': isActive }">
      <li class="hjem"><router-link to="/" @click="toggleNav">Hjem</router-link></li>
      <li><router-link to="/ydelser" @click="toggleNav">Ydelser</router-link></li>
      <li><router-link to="/omos" @click="toggleNav">Om os</router-link></li>
      <li><router-link to="/kontakt" @click="toggleNav">Kontakt</router-link></li>
      <div class="line-box">
        <a href="tel:+4521375039">Tlf: 21 37 50 39</a>
      </div>
    </ul>
    <div class="burger" @click="toggleNav">
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    toggleNav() {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style scoped>
.logo {
    width: 8vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  overflow: hidden;
}

.navbar .logo {
  font-size: 1.5rem;
}

.nav-links {
    font-family: "Exo 2", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.nav-links li {
  padding: 0 1rem;
}

.nav-links a {
  color: #000;
  text-decoration: none;
}

.burger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 21px;
}

.burger div, .burgertwo div {
  width: 25px;
  height: 3px;
  background-color: #000;
}

.line-box {
    display: flex;
    align-items: center;
    height: calc(7vh);
    border-left: solid 1px grey;
}

.line-box a {
    margin-left: 20px;
}

.burgertwo {
  display: none;
}

.hjem {
  display: none;
}


@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    right: 0;
    height: 100vh;
    top: 0;
    background-color: #074c77;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;
    font-size: 26px;
    gap: 20px;
  }

  .nav-links a {
    color: #fff;
  }

  .nav-links.nav-active {
    transform: translateX(0);
  }

  .burger {
    display: flex;
  }

  .line-box {
    margin-top: 40px;
    border-left: none;
   }

  .hjem {
    display: block;
  }

   

}
</style>