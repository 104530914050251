<template>
  <footer class="footer">
    <div class="footer-content">
      <p>&copy; 2024 Midtjysk Service ApS. Alle rettigheder forbeholdes.</p>
      <p>CVR: 44971097</p>
      <div class="footer-links">
        <router-link to="/hjem" @click="toggleNav">Hjem</router-link>
        <router-link to="/ydelser" @click="toggleNav">Ydelser</router-link>
        <router-link to="/omos" @click="toggleNav">Om os</router-link>
        <router-link to="/kontakt" @click="toggleNav">Kontakt</router-link>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: #ffffff;
  color: #000000;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin: 5px 0;
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.footer-links a {
  color: #000000;
  text-decoration: none;
  margin: 5px 15px;
  font-weight: bold;
}

.footer-links a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .footer-content {
    padding: 0 10px;
  }

  .footer-links {
    flex-direction: column;
  }

  .footer-links a {
    margin: 10px 0;
  }
}
</style>
