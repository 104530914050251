
<template>
  <section id="about">
    <h2 class="about-title">Om Midtjysk Service ApS</h2>
    <p class="about-intro">Midtjysk Service ApS er dedikeret til at levere skræddersyede løsninger inden for energioptimering af VVS-installationer. 
      <br>Med mange års erfaring i branchen sikrer vi, at vores kunder opnår maksimal effektivitet og besparelser.
    </p>
    <div class="about-pc">
      <div class="about-container">
        <div class="about-cards">
          <h3 class="content-title">Vores Vision</h3>
          <p class="content-text">At være den førende konsulentvirksomhed inden for energioptimering af VVS-systemer.</p>
        </div>
        <div class="about-cards">
          <h3 class="content-title">Vores Mission</h3>
          <p class="content-text">At hjælpe vores kunder med at reducere deres energiforbrug gennem innovative og bæredygtige løsninger.</p>
        </div>
      </div>
      <div class="gunner-container">
        <div class="gunner-content">
          <h3 class="content-title">Ledelse</h3>
          <p class="content-text">Gunner Andersen,<br> Administrerende Direktør</p>
        </div>
        <img class="gunner" src="../assets/images/Gunner.jpg" alt="">
      </div>
    </div>
</section>
</template>

<script>
export default {
  name: 'OmOs'
}

</script>

<style>
#about {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 30px;
  min-height: 70vh;
  line-height: 1.2;
}

.about-pc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-title {
  font-size: 3.5rem;
  padding-bottom: 20px;
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-cards {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.content-title {
  font-size: 24px;
}

.gunner-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.gunner-content {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  gap: 5px;
}

.content-title {
  font-weight: bold;
}

.gunner {
  width: 50%;
  max-width: 300px;
}

.about-intro {
  font-size: 18px;
}

@media (min-width: 768px) {
  .about-pc {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
  }

  .about-container {
    justify-content: space-around;
    width: 50%;
  }

  .about-cards {
    max-width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    border-radius: 5px;
    background-color: rgb(240, 240, 240);
    display: flex;
    padding: 15px;
    flex-direction: column;
    gap: 8px;
  }

  .gunner-container {
    width: 50%;
  }

  .about-intro {
    font-size: 22px;
  }

  .content-text {
    font-size: 20px;
  }
}
</style>